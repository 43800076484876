import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { publishReplay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  deferredPrompt: any;
  message;
  appInstalled;

  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  showInstallMessage: any;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey
      }
    });
    // appInsights.loadAppInsights();
    // appInsights.trackPageView();
  }

  showSplash = true;
  constructor(
    private platform: Platform, 
    private http: HttpClient,
    public alertController: AlertController, private splashScreen: SplashScreen,
    private as: AuthenticationService, 
    private statusBar: StatusBar) {
    this.updateVersion();
    this.getIpAddress();
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {

      setTimeout(() => {
        this.showSplash = false;
      }, 3000);

      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      }

      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);;
      if (isIos() && !isInStandaloneMode()) {
        this.showInstallMessage = true;
      }
    }


    this.initializeApp();
   
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      setTimeout(() => {
        let el: HTMLElement = this.myDiv.nativeElement;
        el.click();
      }, 5000);
    }
  }

  getIpAddress() {
    this.http.get("http://api.ipify.org/?format=json")
      .pipe(publishReplay(), take(1))
      .subscribe(console.log);
  }


  updateVersion() {
    if (environment.production)
      if (!(localStorage.getItem('version') == environment.version)) {
        localStorage.clear();
        this.as.SignOut();
        localStorage.setItem('version', environment.version + "");
        location.reload();
      }
  }

  async check() {
    const response: {
      status: string
    } = await Capacitor.Plugins.CAMERA.requestPermission({
      permission: "CAMERA"
    });
  }
  addToHomeScreen() {
    if (!this.deferredPrompt) return;
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //  console.log('User accepted the A2HS prompt');
        } else {
          //  console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.splashScreen.hide();
      }, 2000);
      this.statusBar.styleDefault();
    });
  }
}
