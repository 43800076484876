import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginGarcomType } from '../_models/LoginGarcom';
import { CryptoService } from './crypto.service';
import { firestoreService } from "./firestore.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  userData;
  
  constructor(
    public fs: firestoreService,
    private http: HttpClient,
    private nc:NavController,
    private cryptoService:CryptoService
  ) {
  }

  login(email: string, senha: string) {
    return this.http.post(environment.apiUrl+'/login-garcom', { email, senha })
      .pipe(map((user:LoginGarcomType) => {
        this.cryptoService.setCurrentUser(user);
        return user;
      }));
  }




  SignOut() {
    localStorage.removeItem(btoa(environment.keys.usuario));
    localStorage.removeItem(btoa(environment.keys.estabelecimento));
    localStorage.removeItem("token");
    let t = [];
    if ((<any>window).ngxOnesignal)
      setTimeout(() => {
        //  console.log('limpando tags');
        (<any>window).ngxOnesignal.push([
          "getTags",
          function (result) {
            (<any>window).ngxOnesignal.push([
              "deleteTags", Object.keys(result),
              function (result) {
              }
            ]);
            //  console.log(t)
          }
        ]);
      }, 3000);
    this.nc.navigateRoot('/login');
  }
}

