import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContaPageModule } from './dashboard/conta/conta.module';
import { SharedModule } from './shared.module';
import { SplashScreenComponent } from './_components/splashscreen.component';
import { RequestInterceptorService } from './_services/request-interceptor.service';
import { NgxMaskModule, IConfig } from 'ngx-mask'

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent, SplashScreenComponent],
    imports: [
        BrowserModule, 
        ZXingScannerModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        NgxQRCodeModule,
        NgxMaskModule.forRoot(),
        ContaPageModule,
        SuperTabsModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        HttpClientModule,
        IonicModule.forRoot(), 
        AppRoutingModule,
        ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production })],
    providers: [
        StatusBar,
        InAppBrowser,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        {provide:HTTP_INTERCEPTORS, useClass:RequestInterceptorService, multi:true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }




