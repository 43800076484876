import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared.module';
import { ContaPageRoutingModule } from './conta-routing.module';
import { ContaPage } from './conta.page';
import { HomeContaComponent } from './home-conta/home-conta.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    TranslateModule.forChild({ isolate: false }),
    ContaPageRoutingModule
  ],
  declarations: [ContaPage, HomeContaComponent], exports: [HomeContaComponent]
})
export class ContaPageModule { }
