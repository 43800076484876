import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { CryptoService } from 'src/app/_services/crypto.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../../access/login/login.component';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-conta',
  templateUrl: './conta.page.html',
  styleUrls: ['./conta.page.scss'],
})
export class ContaPage implements OnInit {
  user: any;
  userAlias;
  loja: any;
  version;
  loading;
  modal;
  constructor(private cryptoService: CryptoService, private modalController: ModalController, private nc: NavController, private as: AuthenticationService, private rt: Router) {
    this.init();
  }


  init() {
    this.loading = false;
    this.user = this.cryptoService.getCurrentUser();
    if (this.user) this.userAlias = this.user?.nome.substr(0, this.user?.nome.indexOf(' ') || this.user?.nome?.length);
    this.version = environment.version;
    setTimeout(() => {
      this.loading = false;
    }, 1300);
  }


  ionViewWillLeave() {
    if (this.modal) this.modal.dismiss();
  }

  async presentModal() {
    this.modal = await this.modalController.create({
      component: LoginComponent,
    });
    this.modal.onDidDismiss().then((d: any) => this.init());

    return await this.modal.present();
  }


  edit(title, slug) {

    let navigationExtras = { state: { title: title, slug: slug } };
    this.rt.navigate(['/conta/edit'], navigationExtras);
  }

  voltar() {
    //window.history.back();
    this.nc.back();
    return;
    this.loja = JSON.parse(localStorage.getItem('loja'));
    if (!this.loja?.idEstabelecimento)
      this.nc.navigateBack('/home');
    else
      this.nc.navigateBack('/cardapio');
    return;
    window.history.back()
  }


  ngOnInit() {
  }

}
