import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EstabelecimentoType } from 'src/app/_models/Estabelecimento';
import { UserType } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CryptoService } from 'src/app/_services/crypto.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-conta',
  templateUrl: './home-conta.component.html',
  styleUrls: ['./home-conta.component.scss'],
})
export class HomeContaComponent implements OnInit,OnDestroy {
  public version = environment.version;
  public user: UserType;
  public estabelecimentoSelecionado:EstabelecimentoType;
  public subscriptions:Array<Subscription>

  constructor(
    private authService:AuthenticationService,
    private nc: NavController, 
    private cryptoService: CryptoService, 
    private menu: MenuController
    ) { 
      this.subscriptions = new Array<Subscription>();
    }

  ngOnInit() {
    let sub1 = this.cryptoService.$authState.subscribe(res=>{
      this.user = res;
    });
    let sub2 = this.cryptoService.$estabelecimentoState.subscribe(res=>{
      this.estabelecimentoSelecionado = res;
    })
    this.subscriptions.push(sub1);
    this.subscriptions.push(sub2);
    this.init();
   }
   
   ngOnDestroy(): void {
     this.subscriptions.map(sub=>{
      sub.unsubscribe();
     })
   }

  init() {
    this.estabelecimentoSelecionado = this.cryptoService.getCurrentEstabelecimento();
    this.user = this.cryptoService.getCurrentUser();
    this.version = environment.version;
  }

  logout() {
    this.authService.SignOut();
    this.nc.navigateRoot(['/login']);
  }

  meusDados(){
    this.nc.navigateRoot(['/conta/meus-dados']);
  }



  voltar() {
    this.nc.back();
  }

  closeMenu(){
    this.menu.close();
  }


}
