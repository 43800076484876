import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { LoginComponent } from '../access/login/login.component';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'header-commom',
  template: `
  <div class="w-100 px-4 mb-4 " [ngClass]="{'bg-warning': inverse, 'bg-white': !inverse}">
<div class="row pt-5 justify-content-between">

  <div class="col">
  <h4 class="font-weight-bolder">{{title}}</h4>
</div>
<div class="col-auto">
<ion-menu-button color="dark"><i style="font-size:.71em" class="fal fa-bars"></i></ion-menu-button>
  </div>
</div>
</div>
`,
})
export class HeaderCommomComponent implements OnInit {
  @Input() title;
  @Input() inverse;
  loading: boolean;
  user: any;
  us: any;
  userAlias: any;
  version: any;
  modal: any;
  constructor(private nc: NavController, private router: Router, private mc: MenuController, public as: AuthenticationService, private modalController: ModalController) { }

  ngOnInit() {
  }

  pop() {
    this.nc.pop();
  }

  ionViewWillLeave() {
    if (this.modal) this.modal.dismiss();
  }

  async presentModal() {
    this.modal = await this.modalController.create({
      component: LoginComponent,
      canDismiss: true,
      keyboardClose: true,
      showBackdrop: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: 'swipe-modal'
    });

    return await this.modal.present();
  }

}
