// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  apiUrl: 'https://api-dev.cardmenu.com.br/',
  apiHost: 'api-dev.cardmenu.com.br',
  // apiUrl: 'http://localhost:8080/SmartMenuMobileService/',
  // apiHost: 'localhost:8080',
  facebook: {
    appId: '2229197144019612'
  },
  instrumentationKey: 'c3e5da37-fbe1-4261-84e4-3619e3c90ada',
  baseNumberOrder: 15012,
  firebase: {
    apiKey: "AIzaSyACDEqgz_X0eV0zNhQ5eTrdGUS8niVCheE",
    authDomain: "cardmenu.com.br",
    databaseURL: "https://udicarte.firebaseio.com",
    projectId: "udicarte",
    storageBucket: "udicarte.appspot.com",
    messagingSenderId: "853383689908",
    appId: "1:853383689908:web:91d4d82870ae72bd1109e1",
    measurementId: "G-X48SZK3RN3"
  },
  appInsights: {
    instrumentationKey: '260efbdw7-681f-48d0-9ed9-b772a3930f61'
  },
  recaptchaKey: "6LfPJs0UAAAAAOFrMWQqaPRsMQ-n4vlwuIAdyhUe",
  oneSignal: {
    appId: "b919186b-f797-4349-be1d-80171b391a91",
    restApiKey: "NDdmOTk0MTUtMGM0MS00NzM1LWE3ZWEtMjNiY2ExZWY5YTAy"
  },
  version: require('../../package.json').version,
  keys: {
    storage: "31b0LKLTx",
    metodoDePagamento: '9cardapio7online8qeM3qWTpn7gbQe5nGmq4',
    usuario: "usuario",
    estabelecimento: "estabelecimento"
  },
  defaultLogin: {
    username: "camilopinheiro@live.com",
    password: "senha2"
  }
};



