import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/_services/shared.service'
import { LoadingOptions } from 'src/app/_interfaces/LoadingOptions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  sb: any;
  email;
  senha;
  defaultLanguage;
  returnUrl;
  loading: boolean = false;
  private loadingOpt:LoadingOptions;
  private loadingElement:HTMLIonLoadingElement
  constructor(
    private route: ActivatedRoute,
    public modalController: ModalController,
    public authService: AuthenticationService,
    public viewCtrl: ModalController,
    private lc: LoadingController,
    private nc: NavController,
    private ss:SharedService
  ) {
    this.defaultLanguage = localStorage.getItem('defaultLanguage') || 'pt';
    this.loadingOpt = {
      spinner: 'circular',
      cssClass:'loading-design',
    }
  }

  ngOnInit() {
    this.createLoading();
    this.returnUrl = (this.route.snapshot.queryParams['returnUrl']) ? this.route.snapshot.queryParams['returnUrl'] : null;
    if (!environment.production) {
      this.email = `camilopinheiro@live.com`
      this.senha = `senha2`
    }
  }

  close() {
    this.modalController.dismiss();
  }

  async createLoading() {
   this.loadingElement = await this.lc.create(this.loadingOpt);
  }



  login() {
    this.loadingElement.present();
    this.authService.login(this.email, this.senha).subscribe(r => {
      if(r?.idUsuario){
        this.redireciona();
      }else{
        this.ss.show('Usuário inválido!','danger');
      }
      this.loadingElement.dismiss();
    },(err)=>{
      this.loadingElement.dismiss();
      this.ss.show('Falha ao logar, verifique o e-mail e a senha','danger');
      console.log(err)
    })
  }

  redireciona() {
    this.nc.navigateRoot('/');
  }

  toggleLang(lang) {
    localStorage.setItem('defaultLanguage', lang);
    this.defaultLanguage = lang;
    // this.translate.setDefaultLang(lang);
    // this.translate.use(lang);
  }
}
