import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EstabelecimentoType } from '../_models/Estabelecimento';
import { UserType } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  $authState = new Subject<UserType>();
  $estabelecimentoState = new Subject<EstabelecimentoType>();

  constructor() { }

  authState(): Observable<UserType> {
    return this.$authState as Observable<UserType>;
  }

  estabelecimentoState(): Observable<EstabelecimentoType> {
    return this.$estabelecimentoState as Observable<EstabelecimentoType>;
  }

  setCurrentUser(user:UserType) {
    if (!user) return;
    if (user.token) {
      localStorage.setItem(
        "token", user.token,
      );
      localStorage.setItem(
        btoa(environment.keys.usuario),
        AES.encrypt(
          JSON.stringify(user),
          environment.keys.storage
        ).toString()
      );
      this.$authState.next(user);
    }
  }

  getCurrentUser():UserType {
    if (localStorage.getItem(btoa(environment.keys.usuario))) {
        try{
            return JSON.parse(
                AES.decrypt(
                    localStorage.getItem(btoa(environment.keys.usuario)),
                    environment.keys.storage
                ).toString(enc.Utf8)
            );
        }catch(err){
            return null;
        }
    }
    return null;
  }

  setCurrentEstabelecimento(estabelecimento:EstabelecimentoType){
    let estabelecimentoString:string = JSON.stringify(estabelecimento);
    localStorage.setItem(btoa(environment.keys.estabelecimento), AES.encrypt(estabelecimentoString, environment.keys.storage).toString());
    this.$estabelecimentoState.next(estabelecimento);
    return true;
  }

  getCurrentEstabelecimento():EstabelecimentoType{
    if(localStorage.getItem(btoa(environment.keys.estabelecimento))){
      return JSON.parse(
        AES.decrypt(
          localStorage.getItem(btoa(environment.keys.estabelecimento)),
          environment.keys.storage
        ).toString(enc.Utf8)
      );
    }else{
      return null;
    }
  }
}
