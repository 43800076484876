import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
// import { TranslateModule } from '@ngx-translate/core';
import { HeaderCommomComponent } from './_components/header-commom.component';
import { LogoComponent } from './_components/logo.component';



@NgModule({
  declarations: [
    LogoComponent,
    HeaderCommomComponent,
  ],
  imports: [
    IonicModule,
    // TranslateModule,
    CommonModule, RouterModule, FormsModule,
  ],
  exports: [HeaderCommomComponent, LogoComponent]
})
export class SharedModule { }
