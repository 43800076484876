import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
type color = 'danger' | 'dark' | 'light' | 'medium' | 'primary' | 'secondary' | 'success' | 'tertiary' | 'warning' ;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private lojaAtual = new BehaviorSubject((localStorage.getItem('loja') ? JSON.parse(localStorage.getItem('loja')) : null));
  private communicate = {};


  constructor(private toast: ToastController) {
  }


  store(key, value) {
    const t = (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : [];
    localStorage.setItem(key, JSON.stringify([...t, value]));
  }

  setLojaAtual(loja) {
    this.lojaAtual.next(loja);
  }

  getLojaAtual(): Observable<any> {
    return this.lojaAtual.asObservable();
  }

  sendMessage(key, message) {
    if (this.communicate[key]) this.communicate[key]?.next(message);
    else
      this.communicate[key] = new ReplaySubject(message);
  }

  getMessage(key): Observable<any> {
    this.communicate[key] = this.communicate[key] || new ReplaySubject();
    return this.communicate[key]?.asObservable();
  }

  async show(message, color?:color) {
    const toast = await this.toast.create({
      message: message,
      color: color,
      buttons: [],
      animated: true,
      duration: 4000
      , position: 'top'
    });
    toast.present()
  }
}
